<template>
    <div>
        <img class="whatsapp-icon" src="@/assets/imgs/whatsapp_icon.png" alt="whatsapp_icon" @click="goLink" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            initData: 'app/initData',
        }),
    },
    methods: {
        goLink() {
            if (!this.initData.whatsAppPhone && !this.initData.whatsAppText) return;
            let a = document.createElement('a');
            a.target = '_blank';
            a.href = `https://api.whatsapp.com/send?phone=${this.initData.whatsAppPhone.content}&text=${this.initData.whatsAppText.content}`;
            a.click();
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
    .whatsapp-icon {
        position: fixed;
        bottom: 40px;
        right: 40px;
        z-index: 99;
        width: 100px;
        cursor: pointer;
    }
}
@media (max-width: 768px) {
    .whatsapp-icon {
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 99;
        width: 70px;
        cursor: pointer;
    }
}
</style>
