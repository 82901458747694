export const m = {
    apply_for_cooperation: 'Apply for cooperation',
    cooperation: 'Cooperation',
    flexible_payment: 'Flexible payment, support multiple languages',
    make_an_appointment: 'Make an appointment for installation, contact us',
    name: 'Name',
    please_type_in_your_name: 'Please type in your name',
    company_name: 'Company name',
    please_enter_company_name: 'Please enter company name',
    tel_number: 'TEL Number',
    please_enter_your_phone_number: 'Please enter your phone number',
    hong_kong: '852 (Hong Kong, China)',
    mailbox: 'Mailbox',
    please_input_email: 'Please input email',
    remark: 'Remark',
    briefly_describe_your_needs: 'Briefly describe your needs',
    submit: 'Submit',
    incorrect_phone_format: 'Incorrect phone format',
    please_input_your_email: 'please input your email',
    email_format_incorrect: 'E-mail format is incorrect',
    submitted_successfully: 'Submitted successfully',
    thank_you_for_your_attention: 'Thank you for your attention, we will  contact you as soon as possible',
    product_introduction: 'Product introduction',
    product_safety: 'Product safety',
    product_parameter: 'Product parameter',
    product_model_display: 'Product model display',
    use_introduction: 'Use introduction',
    distributed_platform: 'Distributed platform',
    usage_scenarios: 'Usage scenarios',
    apply_to_join: 'Apply to join',
    about_us: 'About us',
    regulations_on_use: 'Regulations on use',
    privacy_agreement: 'Privacy agreement',
    download_i_charge: 'Download "iHK"',
    download_i_charge_canary: 'Download "iHK"[canary]',
    download_i_charge_merchant: 'Download "iHK(Merchant)"',
    download_i_charge_merchant_canary: 'Download "iHK(Merchant)"[canary]',
    charge_in_whenever_necessary: 'Stay with your heart.Fully charge your life',
    merchant_download: 'Merchant download',
    client_download: 'Client download',
    no_source: 'No Source',
    open: 'Open',
    client_download_or_open: 'Client download or open',
    ch:'gender',
    mr: 'Mr',
    mi: 'Miss',
    ly:'leave a message',
};
