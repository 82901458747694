import Vue from 'vue';
import Router from 'vue-router';
import ichargeRouter from './icharge';
import icateringRouter from './icatering';
Vue.use(Router);
console.log(process.env.VUE_APP_ROUTER_MODE);
export default new Router({
  mode: process.env.VUE_APP_ROUTER_MODE,
  routes: [
    {
      path: '/',
      name: 'index',
      meta: {
        showHeadAndFoot: true,
      },
      component: () => import('../views/index.vue'),
    },
    {
      path: '/icatering',
      name: 'icatering',
      meta: {
        showHeadAndFoot: true,
      },
      component: () => import('../views/icatering.vue'),
    },
    {
      path: '/icharge',
      name: 'icharge',
      meta: {
        showHeadAndFoot: true,
      },
      component: () => import('../views/icharge.vue'),
    },
    {
      path: '/about',
      name: 'about',
      meta: {
        showHeadAndFoot: true,
      },
      component: () => import('../views/about.vue'),
    },
    {
      path: '/agreement',
      name: 'agreement',
      meta: {
        name: 'terms_conditions',
      },
      component: () => import('../views/agreement.vue'),
    },
    ...ichargeRouter,
    ...icateringRouter,
    {
      path: '*',
      name: 'notFound',
      redirect: (to) => {
        return { path: '/' };
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    // if (to.params.domId) {
    //     document.getElementById(to.params.domId).scrollIntoView({ behavior: 'smooth' });
    // } else {
    // 始终滚动到顶部
    window.scrollTo({ top: 0 });
    // }
  },
});
