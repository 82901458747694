// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 轮播
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css';
import App from './App';
import router from './router';

import '@/style/default.css';
import "@/style/global.scss"; // 公共样式

import store from './store/index';

import VueI18n from 'vue-i18n';

Vue.use(ElementUI);
Vue.use(VueI18n); 
Vue.use(VueAwesomeSwiper)
//定义接口对象
Vue.prototype.$api = {};

//挂载工具函数
import Util from './utils/storage';
Vue.use(Util);

const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'zh_HK', // 语言标识
    // this.$i18n.locale // 通过切换locale的值来实现语言切换
    messages: {
        // 'zh_CN': require('../public/lang/zh'),   // 中文语言包
        en_US: require('./assets/lang/en'), // 英文语言包
        zh_HK: require('./assets/lang/hk'), // 繁体
        zh_CN: require('./assets/lang/cn'), // 简体
    },
});

Vue.config.productionTip = false; 
Vue.prototype.$mediaURL = 'https://file.ihk.ltd/'; 
const app = new Vue({
    store,
    i18n,
    router,
    render: (h) => h(App),
});

//挂载接口
import httpApi from './api/axios.js';
Vue.use(httpApi, app);

app.$mount('#app');
