export default {
    namespaced: true,
    state: {
        init_data: {},
        ihk_data:{},
        icharge_download: {},
        icharge_data: {},
        icatering_download: {},
        icatering_data: {},
        icatering_HD_data:{},
        navList: [],
        comtent: {},
    },
    getters: {
        initData(state) {
            return state.init_data;
        },
        ihkData(state) {
            return state.ihk_data;
        },
        ichargeDownloadData(state) {
            return state.icharge_download;
        },
        ichargeData(state) {
            return state.icharge_data;
        },
        icateringDownloadData(state) {
            return state.icatering_download;
        },
        icateringData(state) {
            return state.icatering_data;
        },
        icateringHdData(state) {
            return state.icatering_HD_data;
        },
        navList(state) {
            return state.navList;
        },
        comtent(state) {
            return state.comtent;
        },
    },
    mutations: {},
    actions: {
        init({ state }, params) {
            state.init_data = params;
        },
        ihkData({ state }, params) {
            state.ihk_data = params;
        },
        ichargeDownload({ state }, params) {
            state.icharge_download = params;
        },
        ichargeData({ state }, params) {
            state.icharge_data = params;
        },
        icateringDownload({ state }, params) {
            state.icatering_download = params;
        },
        icateringData({ state }, params) {
            state.icatering_data = params;
        },
        icateringHdData({ state }, params) {
            state.icatering_HD_data = params;
        },
    },
};
