const install = (Vue) => {
    Vue.prototype.$getItem = (name) => {
        return localStorage.getItem(name);
    };
    Vue.prototype.$setItem = (name, value) => {
        return localStorage.setItem(name, value);
    };
    Vue.prototype.$removeItem = (name) => {
        return localStorage.removeItem(name);
    };
    // 系统区分
    Vue.prototype.getOS = () => {
        var u = navigator.userAgent;
        if (!!u.match(/compatible/i) || u.match(/Windows/i)) {
            return 'windows';
        } else if (!!u.match(/Macintosh/i) || u.match(/MacIntel/i)) {
            return 'macOS';
        } else if (!!u.match(/iphone/i) || u.match(/Ipad/i)) {
            return 'ios';
        } else if (!!u.match(/android/i)) {
            return 'android';
        } else {
            return 'other';
        }
    };
};

export default { install };
