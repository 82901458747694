<template>
    <div id="app">
        <Head v-if="showHeadAndFoot"></Head>
        <router-view v-if="isReload" />
        <Foot v-if="showHeadAndFoot"></Foot>
        <WhatsApp v-if="showHeadAndFoot"></WhatsApp>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import Head from '@/components/head.vue';
import Foot from '@/components/foot.vue';
import WhatsApp from '@/components/whatsapp.vue';
const isCanary = process.env.NODE_ENV == 'canary';
export default {
    name: 'App',
    components: {
        Head,
        Foot,
        WhatsApp,
    },
    async created() {
        //1=其他,2=充电宝,3=投资人,4=餐饮,5=ihk官网（首页、关于）,6=i充电,7=i餐饮
        // 基本配置
        const initResult = await this.$api.getInit({ source: 5 });
        if (initResult.code == 200) {
            // 灰度环境 将灰度二维码替换成当前二维码
            if (isCanary) {
                initResult.data.ihk_code = initResult.data.ihk_code_canary;
                initResult.data.repast_merchant_code = initResult.data.repast_merchant_code_canary;
                initResult.data.repast_merchanthd_code = initResult.data.repast_merchanthd_code_canary;
                initResult.data.charge_merchant_code = initResult.data.charge_merchant_code_canary;
            }
            this.init(initResult.data);
        }
        // iHK
        const ihkResult = await this.$api.getContent({ locale: this.$i18n.locale, source: 5, platform: 'pc' });
        if (ihkResult.code == 200) {
            ihkResult.data.index_banner.content.list = ihkResult.data.index_banner && ihkResult.data.index_banner.content && ihkResult.data.index_banner.content.list.reverse();
            this.ihkData(ihkResult.data);
            this.$nextTick(() => {
                this.isReload = true;
            });
        }

        //充電寶获取配置
        this.$api.getDownloadInit({ locale: this.$i18n.locale, source: 2 }).then((res) => {
            if (res.code == 200) {
                if (isCanary) {
                    res.data.iosUser = res.data.iosUser_canary;
                    res.data.iosMerchant = res.data.iosMerchant_canary;
                    res.data.googleMerchant = res.data.googleMerchant_canary;
                    res.data.googleUser = res.data.googleUser_canary;
                }
                this.ichargeDownload(res.data);
            }
        });
        // 餐饮获取配置
        this.$api.getDownloadInit({ locale: this.$i18n.locale, source: 4 }).then((res) => {
            if (res.code == 200) {
                if (isCanary) {
                    res.data.downloadLink = res.data.downloadLink_canary;
                    res.data.downloadLink_google = res.data.downloadLink_google_canary;
                }
                this.icateringDownload(res.data);
            }
        });
        // iCharge
        this.$api.getContent({ locale: this.$i18n.locale, source: 6, platform: 'pc' }).then((res) => {
            if (res.code == 200) {
                this.ichargeData(res.data);
            }
        });
        // iCatering
        this.$api.getContent({ locale: this.$i18n.locale, source: 7, platform: 'pc' }).then((res) => {
            if (res.code == 200) {
                this.icateringData(res.data);
            }
        });
        // iCateringHD
        this.$api.getContent({ locale: this.$i18n.locale, source: 8, platform: 'pc' }).then((res) => {
            if (res.code == 200) {
                if (isCanary) {
                    res.data.downloadLink = res.data.downloadLink_canary;
                    res.data.downloadLink_google = res.data.downloadLink_google_canary;
                }
                this.icateringHdData(res.data);
            }
        });
    },
    data() {
        return {
            isReload: false,
            showHeadAndFoot: false,
        };
    },
    provide() {
        return {
            reload: this.reload,
        };
    },
    methods: {
        ...mapActions({
            init: 'app/init',
            ihkData: 'app/ihkData',
            ichargeDownload: 'app/ichargeDownload',
            ichargeData: 'app/ichargeData',
            icateringDownload: 'app/icateringDownload',
            icateringData: 'app/icateringData',
            icateringHdData: 'app/icateringHdData',
        }),
        reload() {
            this.isReload = false;
            this.$nextTick(() => {
                this.isReload = true;
            });
        },
    },
    watch: {
        '$route.name': {
            handler(newV, oldV) {
                this.showHeadAndFoot = (this.$route.meta && this.$route.meta.showHeadAndFoot) || false;
                if (newV && newV.includes('icharge')) {
                    document.title = 'iHK-i充電-尿袋租借-共享充電寶-0成本免按金-加盟尿袋機櫃';
                } else if (newV && newV.includes('icatering')) {
                    document.title = 'iHK-i餐飲-堂食-外賣-外賣自取-附近美食-餐飲POS系統-免費入駐';
                } else {
                    document.title = 'iHK一站式香港生活服務平台-外賣-餐廳-市集-堂食-點餐-共享充電寶';
                }
            },
            immediate: true,
        },
    },
};
</script>
