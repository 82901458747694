let routers = [
    {
        path: '/icharge/download',
        name: 'icharge-download',
        component: () => import('../views/icharge/download.vue'),
    },
    {
        path: '/icharge/merchant-download',
        name: 'icharge-merchant-download',
        component: () => import('../views/icharge/merchant-download.vue'),
    },
    {
        path: '/icharge/agreement',
        name: 'icharge-agreement',
        meta: {
            name: 'regulations_on_use',
        },
        component: () => import('../views/icharge/agreement.vue'),
    },
    {
        path: '/icharge/cancellation',
        name: 'icharge-cancellation',
        meta: {
            name: 'regulations_on_cancel',
        },
        component: () => import('../views/icharge/agreement.vue'),
    },
    {
        path: '/icharge/privacypolicy',
        name: 'icharge-privacypolicy',
        meta: {
            name: 'privacy_agreement',
        },
        component: () => import('../views/icharge/agreement.vue'),
    },
    {
        path: '/icharge/deposit',
        name: 'icharge-deposit',
        meta: {
            name: 'deposit_agreement',
        },
        component: () => import('../views/icharge/agreement.vue'),
    },
    {
        path: '/icharge/store',
        name: 'icharge-store',
        component: () => import('../views/icharge/store.vue'),
    },
];

export default routers;
