let routers = [
    {
        path: '/icatering/download',
        name: 'icatering-download',
        component: () => import('../views/icatering/download.vue'),
    },
    {
        path: '/icatering_HD/download',
        name: 'icatering-hd-download',
        component: () => import('../views/icatering/HD_download.vue'),
    },
    {
        path: '/icatering/agreement',
        name: 'icatering-agreeMent',
        meta: {
            name: 'agreement_of_usage',
        },
        component: () => import('../views/icatering/agreement.vue'),
    },
    {
        path: '/icatering/privacypolicy',
        name: 'icatering-privacyPolicy',
        meta: {
            name: 'privacypolicy',
        },
        component: () => import('../views/icatering/agreement.vue'),
    },
];

export default routers