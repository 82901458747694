import axios from 'axios';

const BaseURL = process.env.VUE_APP_BASE_URL;
const OtherUrl = process.env.VUE_APP_OTHER_URL;
const CanaryUrl = process.env.VUE_APP_CANARY_URL;
console.log(process.env);

const http = axios.create({
    baseURL: BaseURL, // api base_url
    headers: {
        'content-type': 'application/json',
    },
    // timeout: 9000 // 请求超时时间
});

const install = (Vue, app) => {
    console.log(localStorage);
    // 请求拦截
    http.interceptors.request.use(
        (config) => {
            //配置语言
            if (typeof config.params != 'undefined') {
                config.params.locale = app.$i18n.locale;
            } else {
                config.params = {
                    locale: localStorage.lang || app.$i18n.locale,
                };
            }
            if (config.url.indexOf('/api/') != 0) {
                config.baseURL = OtherUrl;
            }
            // if (process.env.NODE_ENV == 'tests' && config.url.indexOf('/canary/') != -1) {
            //     config.baseURL = CanaryUrl;
            //     config.url = config.url.replace('/canary/', '/');
            // }
            return config;
        },
        (err) => {
            return Promise.reject(err);
        }
    );

    // 响应拦截
    http.interceptors.response.use(
        (response) => {
            if (response.status == 200) {
                return response.data || {};
            }
            return Promise.reject(response);
        },
        (err) => {
            return Promise.reject(err); // 返回接口返回的错误信息
        }
    );
    //挂载全局
    Vue.prototype.$axios = http;
    //接口
    app.$api.storeInfo = (params = {}) => http.get(`/account/powerbank/business/get/${params}`);
    app.$api.allCoupon = (params = {}) => http.get(`/food/merchant/store_coupons/coupon/${params}`);
    app.$api.getGoodsList = (params = {}) => http.get(`/food/merchant/goods/goods/${params}`);

    // 下載配置(餐飲、充電寶)
    app.$api.getDownloadInit = (params = {}) => http.get('/api/index/init', { params });
    //联系我们
    app.$api.saveContactus = (params = {}) => http.post('/api/contactus/save', params);
    //单页内容（协议）
    app.$api.getPageDetail = (params = {}) => http.get('/api/page/detail', { params });

    // 获取基本配置
    app.$api.getInit = (params = {}) => http.get('/api/index/init', { params });
    // 获取内容配置
    app.$api.getContent = (params = {}) => http.get('/api/index/content', { params });
};

export default { install };
