<template>
    <div class="foot">
        <div class="content df-sb-cen">
            <div class="foot_left">
                <img :src="initData.logo ? initData.logo.content : require('@/assets/imgs/logo.png')" alt="" />
                <div>
                    <p>{{ initData.company_desc && initData.company_desc.content }}</p>
                    <p>{{ initData.company && initData.company.content }}</p>
                    <template v-if="initData.filing && initData.filing_addr">
                        <a :href="initData.filing_addr.content">{{initData.filing.content}}</a>
                    </template>
                </div>
                <div>
                    <p class="strong">客服專線</p>
                    <p>{{ initData.service_hotlin && initData.service_hotlin.content }}</p>
                </div>
                <div>
                    <p class="strong">客服電郵</p>
                    <p>{{ initData.email && initData.email.content }}</p>
                </div>
            </div>
            <div class="foot_warp foot_mid">
                <div class="title">常用連結</div>
                <div class="context">
                    <p @click="goRouter('index')">首頁</p>
                    <p @click="goRouter('about')">關於我們</p>
                    <p>商戶免費使用</p>
                    <p @click="goRouter('agreement')">條款及細則</p>
                </div>
            </div>
            <div class="foot_warp foot_right">
                <div class="title">應用程式</div>
                <div class="context">
                    <ul class="df-sb-cen">
                        <li v-if="initData.ihk_code && initData.ihk_code.content">
                            <img :src="initData.ihk_code.content" alt="" />
                            <p>iHK APP</p>
                        </li>
                        <li v-if="initData.repast_merchant_code && initData.repast_merchant_code.content">
                            <img :src="initData.repast_merchant_code.content" alt="" />
                            <p>i餐飲商戶版</p>
                        </li>
                        <li v-if="initData.repast_merchanthd_code && initData.repast_merchanthd_code.content">
                            <img :src="initData.repast_merchanthd_code.content" alt="" />
                            <p>i餐飲商戶版HD</p>
                        </li>
                        <li v-if="initData.charge_merchant_code && initData.charge_merchant_code.content">
                            <img :src="initData.charge_merchant_code.content" alt="" />
                            <p>i充電商戶版</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({
            initData: 'app/initData',
        }),
    },
    data() {
        return {
            list: [],
        };
    },
    created() {},
    methods: {
        goRouter(name) {
            let routeName = this.$route.name;
            if (name !== routeName) this.$router.push({ name });
        },
    },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
    .foot {
        background: #f9f9f9;
        .content {
            padding: 60px 0;
            .foot_left {
                flex: 2;
                & > * {
                    margin: 20px 0;
                }
                img {
                    width: 64px;
                    margin: 0 !important;
                    image-rendering: -moz-crisp-edges; /* Firefox */
                    image-rendering: -o-crisp-edges; /* Opera */
                    image-rendering: -webkit-optimize-contrast; /*Webkit (non-standard naming) */
                    image-rendering: crisp-edges;
                }
                p {
                    font-size: 16px;
                }
                p.strong {
                    font-size: 18px;
                    font-weight: bold;

                    color: #404040;
                }
            }
            .foot_mid {
                flex: 2;
                p {
                    cursor: pointer;
                }
            }
            .foot_right {
                flex: 3;
            }
            .foot_warp {
                padding-top: 84px;
                .title {
                    font-size: 24px;

                    font-weight: bold;
                }
                .context {
                    padding-top: 26px;
                    height: 170px;
                    p {
                        margin: 8px 0;
                        font-size: 16px;

                        font-weight: 500;
                    }
                    ul {
                        justify-content: flex-start;
                        li { 
                            &:not(:first-child):not(:last-child){
                                margin: 0 20px;
                            }
                            &:nth-child(3){
                                margin-left: 0 !important;
                            }
                            p {
                                text-align: center;
                            }
                            img {
                                width: 130px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media (max-width: 768px) {
    .foot {
        background: #f9f9f9;
        .content {
            width: 90%;
            padding: 60px 0;
            flex-direction: column;
            align-items: flex-start;
            .foot_left {
                flex: 2;
                & > * {
                    margin: 20px 0;
                }
                img {
                    width: 64px;
                    margin: 0 !important;
                    image-rendering: -moz-crisp-edges; /* Firefox */
                    image-rendering: -o-crisp-edges; /* Opera */
                    image-rendering: -webkit-optimize-contrast; /*Webkit (non-standard naming) */
                    image-rendering: crisp-edges;
                }
                p {
                    font-size: 16px;
                }
                p.strong {
                    font-size: 18px;
                    font-weight: bold;

                    color: #404040;
                }
            }
            .foot_mid {
                margin-top: 40px;
                flex: 2;
                p {
                    cursor: pointer;
                }
            }
            .foot_right {
                flex: 3;
            }
            .foot_warp {
                .title {
                    font-size: 20px;

                    font-weight: bold;
                }
                .context {
                    padding-top: 26px;
                    height: 170px;
                    p {
                        margin: 8px 0;
                        font-size: 16px;
                    }
                    ul {
                        justify-content:space-around;
                        align-items:flex-start;
                        li {
                            flex: 1;
                            p {
                                text-align: center;
                            }
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
