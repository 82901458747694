export const m = {
    apply_for_cooperation: '馬上申請合作',
    cooperation: '合作加盟',
    flexible_payment: '靈活支付，支援多國語言',
    make_an_appointment: '預約安裝，聯繫我們',
    name: '姓名',
    please_type_in_your_name: '請輸入姓名',
    company_name: '公司名稱',
    please_enter_company_name: '請輸入公司名稱',
    tel_number: '電話號碼',
    please_enter_your_phone_number: '请输入電話號碼',
    hong_kong: '+852（中國香港）',
    mailbox: '郵箱',
    please_input_email: '請輸入郵箱',
    remark: '備註',
    briefly_describe_your_needs: '簡單描述一下你的需求',
    submit: '提交',
    incorrect_phone_format: '手機格式不正確',
    please_input_your_email: '請輸入郵箱',
    email_format_incorrect: '郵箱格式不正確',
    submitted_successfully: '提交成功',
    thank_you_for_your_attention: '感謝您的關注，我們將盡快與您聯繫',
    product_introduction: '產品介紹',
    product_safety: '產品安全性',
    product_parameter: '產品參數',
    product_model_display: '產品機型展示',
    use_introduction: '使用介紹',
    distributed_platform: 'iHK本地信息分類平台',
    usage_scenarios: '使用場景',
    apply_to_join: '申請加盟',
    about_us: '關於我們',
    regulations_on_use: '使用條例',
    privacy_agreement: '私隱協議',
    download_i_charge: '下載“iHK”',
    download_i_charge_canary: '下載“iHK”[灰度]',
    download_i_charge_merchant: '下載“iHK（商戶版）”',
    download_i_charge_merchant_canary: '下載“iHK（商戶版）”[灰度]',
    charge_in_whenever_necessary: '以心維繫為你的生活充滿電',
    merchant_download: '商家端下載',
    client_download: '用戶端下載',
    no_source: '系统暂无资源下载',
    open: '打开',
    client_download_or_open: '用户端下载或打开',
    ch: '称呼',
    mr: '先生',
    mi: '女士',
    ly:'留言',
};
