<template>
    <div class="head" :class="isTrans ? 'index' : 'noIndex'">
        <div class="content df-sb-cen">
            <div class="logo" >
                <img :src="initData.logo ? initData.logo.content : require('@/assets/imgs/logo.png')" alt="" />
            </div>
            <div class="menu">
                <ul class="df-sa-cen">
                    <li :class="routeName === 'index' ? 'active' : ''" @click="goRouter('index')">iHK</li>
                    <li :class="routeName === 'icatering' ? 'active' : ''" @click="goRouter('icatering')">i餐飲</li>
                    <li :class="routeName === 'icharge' ? 'active' : ''" @click="goRouter('icharge')">i充電</li>
                    <li :class="routeName === 'about' ? 'active' : ''" @click="goRouter('about')">關於iHK</li>
                </ul>
            </div>
            <div class="menu-mobile">
                <ul class="df-sa-cen">
                    <li class="df-sb-cen" :class="routeName === 'index' ? 'active' : ''" @click="changePop()">
                        <img src="@/assets/imgs/head/Menu@2x.png" alt="" />
                        <div>{{ mobileRouterName }}</div>
                    </li>
                </ul>
            </div>
        </div>

        <el-dialog class="route-dialog" :visible.sync="dialog" width="90%" :show-close="false" :append-to-body="true" :before-close="changePop">
            <div class="route-box">
                <ul class="df-col">
                    <li :class="routeName === 'index' ? 'active' : ''" @click="goRouter('index')">iHK</li>
                    <li :class="routeName === 'icatering' ? 'active' : ''" @click="goRouter('icatering')">i餐飲</li>
                    <li :class="routeName === 'icharge' ? 'active' : ''" @click="goRouter('icharge')">i充電</li>
                    <li :class="routeName === 'about' ? 'active' : ''" @click="goRouter('about')">關於iHK</li>
                </ul>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    computed: {
        ...mapGetters({ 
            initData: 'app/initData',
        }),
    },
    data() {
        return {
            navIndex: 0,
            isTrans: true,
            isIndex: true,
            routeName: 'index',
            scrollTop: 0,
            dialog: false,
            mobileRouterName: '選單',
        };
    },
    watch: {
        '$route.name': {
            handler(newV, oldV) {
                this.routeName = newV;
                this.isIndex = newV === 'index';
                this.isTrans = this.isIndex;
                switch (newV) {
                    case 'icharge':
                        this.mobileRouterName = 'i充電';
                        break;
                    case 'icatering':
                        this.mobileRouterName = 'i餐飲';
                        break;
                    case 'about':
                        this.mobileRouterName = '關於iHK';
                        break;
                    default:
                        this.mobileRouterName = '選單';
                }
            },
            immediate: true,
        },
        scrollTop: {
            handler: function (nv, ov) {
                !this.isIndex && (this.isTrans = nv >= 200);
            },
            immediate: true,
        },
    },
    created() { 
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            this.scrollTop = scrollTop;
        },
        goRouter(name) {
            if (name === this.routeName) return;
            this.dialog = false;
            this.$router.push({ name });
        },
        changeLanguage(lang) {
            this.$setItem('lang', lang);
            // this.$i18n.locale = lang;
            window.location.reload();
        },
        toIndex() {
            this.scrollTo('banner', 0);
        },
        scrollTo(dom, index) {
            // this.navIndex = index || 0
            document.getElementById(dom).scrollIntoView({ block: 'start', behavior: 'smooth' });
        },
        changePop() {
            this.dialog = !this.dialog;
        },
    },
};
</script>

<style lang="scss" scoped>
.head {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
}
.index {
    background: url('~@/assets/imgs/head/head_bg.png') no-repeat;
    background-size: 100% 100%;
}

.noIndex {
    background-color: transparent;
}
@media (min-width: 768px) {
    .head {
        .content {
            height: pc(143);
            .logo {
                img {
                    width: pc(106);
                    height: pc(106);
                }
            }
            .menu {
                width: pc(400);
                height: pc(57);
                background: #ffffff;
                box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
                border-radius: pc(16);
                ul {
                    height: 100%;
                    padding: 0 20px;
                    li {
                        font-size: pc(24);
                        font-weight: 600;
                        color: #333333;
                        cursor: pointer;
                        &.active {
                            color: var(--theme-color);
                        }
                    }
                }
                &-mobile {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .head {
        .content {
            width: 90%;
            height: pc(128);
            .logo {
                img {
                    width: pc(94);
                }
            }
            .menu {
                display: none;
            }
            .menu-mobile {
                min-width: 100px;
                height: pc(57);
                background: #ffffff;
                box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
                border-radius: pc(16);
                ul {
                    height: 100%;
                    padding: 0 10px;
                    li {
                        font-size: 16px;
                        font-weight: 600;
                        color: #44bcb9;
                        cursor: pointer;
                        img {
                            width: 18px;
                            margin-right: 12px;
                        }
                    }
                }
            }
        }
    }
    .route-dialog {
        /deep/ .el-dialog {
            box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25), 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
            border-radius: 16px;
        }
        /deep/ .el-dialog__header {
            display: none;
        }
        .route-box {
            ul {
                li {
                    padding: 24px 0;
                    font-size: 18px;
                    font-weight: 600;
                    color: #404040;
                    text-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15), 0px 1px 0px rgba(0, 0, 0, 0.05);
                    &:not(:last-child) {
                        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
                    }
                    &.active {
                        color: #44bcb9;
                    }
                }
            }
        }
    }
}
</style>
